import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { tileList } from '../services/dashboard';
import { useNavigate } from 'react-router-dom';
import {
  RPSTileItem,
  RPSListContainer,
  RPSImage,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';
import { useCurrentDealSource } from '../hooks/useCurrentDealSource';
import { ApolloClients } from './../services/apolloClients';
import { GET_ODO_IMAGES } from './../gql/deals/getODOImages';
import { generatePath } from 'react-router-dom';
import { getDisplayImage } from '@odo/utils/images';
import { BASE_PATH } from '@odo/screens/deal/editor/constants.ts';

const Dashboard = () => {
  const [, setDeals] = useState([]);
  const [dealOptions, setDealOptions] = useState([]);
  const currentDeal = useCurrentDealSource();

  useEffect(() => {
    setDeals([]);
    setDealOptions(tileList);
  }, []);

  const dealMenu = () => {
    return (
      <Fragment>
        {dealOptions.map((x, index) => (
          <LinkTile
            to={x.url}
            id={'deal-option-' + x.id}
            icon={x.icon}
            color={x.color}
            text={x.text}
            key={index}
            beforeNav={() => {
              if (x.action === 'create') currentDeal.newDeal();
            }}
          />
        ))}
      </Fragment>
    );
  };

  return (
    <div>
      <h3
        style={{
          gridColumn: '1/5',
          textAlign: 'center',
          paddingBottom: '1rem',
        }}
      >
        Quick Links
      </h3>
      <RPSListContainer className="horizontal" css="* { padding: 2rem; }">
        {dealMenu()}
      </RPSListContainer>

      <h3
        style={{
          gridColumn: '1/5',
          textAlign: 'center',
          paddingBottom: '1rem',
        }}
      >
        Recent Deals
      </h3>
      <RPSListContainer
        css="#list-container { place-content: center; }"
        className="horizontal"
      >
        <Fragment>
          {currentDeal.recentDealList.map((x, index) => (
            <DealTile id={'recent-deal-' + x.id} key={index} deal={x} />
          ))}
        </Fragment>
      </RPSListContainer>
    </div>
  );
};

const LinkTile = ({
  color,
  text,
  icon,
  to = '/dashboard',
  beforeNav = null,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (beforeNav) {
      beforeNav();
    }
    if (to.startsWith('http://') || to.startsWith('https://')) {
      window.open(to, '_blank');
    } else {
      navigate(to);
    }
  };

  return (
    <RPSTileItem
      css={`
        :host {
          flex: 1;
          --tile-color: ${color};
        }
      `}
      cbClick={handleNavigate}
    >
      <div slot="slot-icon">
        <rps-svg svg={icon} />
      </div>
      {text}
      <rps-svg svg={iconNames.angleRight} />
    </RPSTileItem>
  );
};

// NOTE: no longer the full deal model. @see createRecentDealEntry
const DealTile = ({ deal }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState(
    deal?.image || '/assets/image-placeholder.png'
  );

  // Effect: Fetch deal images on load
  useEffect(() => {
    if (!deal.image) {
      const client = new ApolloClients();
      const fetchImages = async () => {
        const result = await client.odoClient.query({
          query: GET_ODO_IMAGES,
          variables: {
            productId: deal.id,
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        });

        if (
          result?.data?.getProductImages &&
          result.data.getProductImages.length > 0
        ) {
          const img = getDisplayImage(result.data.getProductImages);
          if (img) {
            setImage(img.url);
          }
        }
      };

      fetchImages();
    }
  }, [deal.id, deal.image]);

  return (
    <RPSTileItem
      css=":host { max-width: 20rem; max-height: 20rem; margin-bottom: 8rem; }"
      className="product"
      cbClick={async ev => {
        ev.preventDefault();
        ev.cancelBubble = true;
        ev.stopPropagation();

        navigate(
          generatePath(`${BASE_PATH}/:dealId?/buyer-and-supplier`, {
            dealId: deal.id,
          })
        );
      }}
    >
      <RPSImage
        slot="media"
        css=":host img { width: 20rem; height: 20rem; object-fit: contain !important;}"
        src={`${image}?h=220&w=220`}
      />
      <h6 slot="heading">{deal.brand}</h6>
      <div slot="product-caption">{deal.name}</div>
    </RPSTileItem>
  );
};

DealTile.propTypes = {
  deal: PropTypes.any,
};

LinkTile.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  beforeNav: PropTypes.func,
};

export default Dashboard;
