import SkuAvailable from './sku-available';
import SurchargeWeight from './surcharge-weight';
import SurchargeTotal from './surcharge-total';
import UniqueImagePositions from './unique-image-positions';
import SyncPriceToCustomOptions from './sync-price-to-custom-options';
import SyncCustomOptionsQty from './sync-custom-options-qty';
import SupplierVoucher from './supplier-voucher';

const SideEffects = () => (
  <>
    <SkuAvailable />
    <SurchargeWeight />
    <SurchargeTotal />
    <UniqueImagePositions />
    <SyncPriceToCustomOptions />
    <SyncCustomOptionsQty />
    <SupplierVoucher />
  </>
);

export default SideEffects;
