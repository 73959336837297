import { calcSurchargeInsurance } from '@odo/helpers/calculations/general';
import { SURCHARGE_INSURANCE_KEY } from '@odo/screens/deal/editor/constants';
import { Status, type Validator } from '@odo/screens/deal/editor/types';
import { isPositiveNumeric } from '@odo/utils/validation';

export const priceSectionValidators: Validator[] = [
  {
    validate: product => isPositiveNumeric(product.cost?.number),
    message: 'Cost is required.',
    status: Status.error,
  },
  {
    validate: product => isPositiveNumeric(product.price?.number),
    message: 'Price is required.',
    status: Status.error,
  },
  {
    validate: product => !!product.adminCost?.id,
    message: 'You must select an admin cost.',
    status: Status.error,
  },
  {
    validate: product =>
      !product.cost?.number ||
      !product.price?.number ||
      product.cost?.number <= product.price?.number,
    message: 'Cost is greater than price.',
    status: Status.warning,
  },
  {
    validate: product => isPositiveNumeric(product.retail?.number),
    message: 'No retail price entered.',
    status: Status.warning,
  },
];

export const surchargesSectionValidators: Validator[] = [
  {
    validate: product =>
      product.isDeliveredBySupplier ||
      typeof product.cost?.number !== 'number' ||
      // NOTE: for tech products (eg. cellphone) this would ideally be 6500, but we can't reliably identify those
      product.cost.number <= 10000 ||
      (product.surcharges || []).find(s => s.id === SURCHARGE_INSURANCE_KEY)
        ?.value.number === calcSurchargeInsurance(product.cost.number),
    message: "Surcharge (Insurance) doesn't match the expected value.",
    status: Status.warning,
  },
];

export const priceCalculatorSectionValidators: Validator[] = [];

export const customOptionsSectionValidators: Validator[] = [];

const validators: Validator[] = [
  ...priceSectionValidators,
  ...surchargesSectionValidators,
  ...priceCalculatorSectionValidators,
  ...customOptionsSectionValidators,
];

export default validators;
