import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { Heading } from '@odo/components/elements/typography';
import { useChangeProduct } from '@odo/contexts/product-editor';
import type { EditorProductSizeInfoImage } from '@odo/types/portal';
import { useCallback, useRef } from 'react';
import { UnstyledButton } from '@odo/components/elements/button';
import Card from '@odo/components/elements/card';
import FileDropzone from '@odo/components/widgets/file-dropzone';
import { ACCEPTED_IMAGE_TYPES } from '@odo/screens/deal/editor/constants';
import ImagePreviewPrimitive from '@odo/screens/deal/editor/widgets/images/image-preview';
import { SizeInfoImageActions } from '@odo/screens/deal/editor/product/widgets';
import { NewImageIndicator } from '@odo/screens/deal/editor/widgets/images/image-preview';
import { FaRegImages as IconImages } from 'react-icons/fa';
import type { FileDropzoneRef } from '@odo/components/widgets/file-dropzone/file-dropzone';
import DeletedOverlay from '@odo/screens/deal/editor/widgets/deleted-overlay';

const SizeInfoImage = ({ image }: { image: EditorProductSizeInfoImage }) => {
  const change = useChangeProduct();

  const fileDropzoneRef = useRef<FileDropzoneRef | null>(null);

  const onFileDrop = useCallback(
    (files: File[]) => {
      const [file] = files;
      if (!file) return;

      change({
        fieldId: `sizeInfo.${image.id}.file`,
        label: `Size Info - New ${image.label} Image`,
        screen: 'product',
        apply: to =>
          (to.sizeInfo = {
            ...to.sizeInfo,
            [image.id]: {
              // NOTE: we keep the old data in case the user cancels the upload
              ...(to.sizeInfo ? to.sizeInfo[image.id] : {}),
              id: image.id,
              label: image.label,
              file,
            },
          }),
      });
    },
    [image, change]
  );

  return (
    <FileDropzone
      ref={fileDropzoneRef}
      acceptedTypes={ACCEPTED_IMAGE_TYPES}
      onFileDrop={onFileDrop}
    >
      <Grid justifyItems="center" p={1} gap={[2, 3]}>
        <Card
          width="100%"
          mainProps={{ px: [1, 2], py: [2, 3] }}
          headerProps={{ px: [1, 2] }}
          header={
            <Flex justifyContent="space-between" width="100%">
              <Heading fontSize={1}>{image.label}</Heading>

              <Flex gap={1}>
                <SizeInfoImageActions image={image} />
              </Flex>
            </Flex>
          }
        >
          <Flex justifyContent="center">
            <Grid gap={[2, 3]}>
              <GridItem gridColumn={1} gridRow={1}>
                <ImagePreviewPrimitive
                  url={image?.url}
                  file={image?.file}
                  dimensions={150}
                />
              </GridItem>

              <GridItem
                gridColumn={1}
                gridRow={1}
                style={{ position: 'relative', pointerEvents: 'none' }}
              >
                {image?.shouldDelete ? (
                  <DeletedOverlay />
                ) : (
                  <Flex
                    justifyContent="flex-end"
                    p={[1, 2]}
                    gap={[1, 2]}
                    style={{
                      zIndex: 2,
                      position: 'relative',
                      pointerEvents: 'auto',
                    }}
                  >
                    {!!image?.file && !image?.shouldDelete && (
                      <NewImageIndicator />
                    )}
                  </Flex>
                )}
              </GridItem>
            </Grid>
          </Flex>
        </Card>

        <IconImages size={22} />

        <Heading fontSize={1} pb={2} px={2} textAlign="center">
          Drag image here or{' '}
          <UnstyledButton onClick={() => fileDropzoneRef.current?.click()}>
            click to upload.
          </UnstyledButton>
        </Heading>
      </Grid>
    </FileDropzone>
  );
};

export default SizeInfoImage;
