/**
 * NOTE: this is a similar namespace to hooks/deal.ts
 * But that file contains some untyped code from RP.
 * So we don't wanna associate it with our fully typed code.
 */
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAttributeContext } from '@odo/hooks/attributes';
import createWithDefaults from '@odo/data/product/draft/create';
import { createDraft } from '@odo/data/product/draft/cache';
import { BASE_PATH } from '@odo/screens/deal/editor/constants';

export const useCreateDeal = ({
  onNavigate,
}: { onNavigate?: () => void } = {}) => {
  const navigate = useNavigate();

  const { isReady, attributes } = useAttributeContext();

  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const createDeal = useCallback(async () => {
    setIsCreating(true);
    setError(undefined);

    try {
      const newProduct = createWithDefaults(attributes);
      const tmpId = await createDraft(newProduct);

      navigate(`${BASE_PATH}/tmp-${tmpId}/buyer-and-supplier`);
      onNavigate && onNavigate();
    } catch (e) {
      setIsCreating(false);
      setError(
        e instanceof Error && typeof e.message === 'string'
          ? e.message
          : 'Error creating new deal'
      );
    }
  }, [attributes, navigate, onNavigate]);

  return { isReady, isCreating, error, createDeal };
};
