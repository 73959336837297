import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { cssColor } from '@odo/utils/css-color';
import { FaRegEyeSlash as IconEyeClosed } from 'react-icons/fa';
import type { EditorProductImage } from '@odo/types/portal';
import { isNewId } from '@odo/utils/uuid';
import Tooltip from '@odo/components/widgets/tooltip';
import ImagePreviewPrimitive from '@odo/screens/deal/editor/widgets/images/image-preview';
import {
  IndicatorWrapper,
  NewImageIndicator,
} from '@odo/screens/deal/editor/widgets/images/image-preview';
import DeletedOverlay from '@odo/screens/deal/editor/widgets/deleted-overlay';

const ExcludedImageIndicator = () => (
  <Tooltip showDelay={500} content={() => 'Image will not be shown'}>
    <IndicatorWrapper>
      <IconEyeClosed color={cssColor('palette-blue')} size={18} />
    </IndicatorWrapper>
  </Tooltip>
);

const ImagePreview = ({
  image,
  dimensions,
}: {
  image: EditorProductImage;
  dimensions: number;
}) => (
  <Grid>
    <GridItem gridColumn={1} gridRow={1}>
      <ImagePreviewPrimitive
        url={image.url}
        file={image.file}
        dimensions={dimensions}
      />
    </GridItem>

    <GridItem
      gridColumn={1}
      gridRow={1}
      style={{ position: 'relative', pointerEvents: 'none' }}
    >
      {image.shouldDelete ? (
        <DeletedOverlay />
      ) : (
        <>
          {!!image.isHidden && (
            <div
              style={{
                position: 'absolute',
                inset: 0,
                background: 'hsla(0deg 0% 100% / 0.75)',
                zIndex: 1,
              }}
            />
          )}

          <Flex
            justifyContent="flex-end"
            p={[1, 2]}
            gap={[1, 2]}
            style={{
              zIndex: 2,
              position: 'relative',
              pointerEvents: 'auto',
            }}
          >
            {!!image.isHidden && <ExcludedImageIndicator />}
            {isNewId(image.id) && <NewImageIndicator />}
          </Flex>
        </>
      )}
    </GridItem>
  </Grid>
);

export default ImagePreview;
