import Card from '@odo/components/elements/card';
import { Flex } from '@odo/components/elements/layout';
import { cssColor } from '@odo/utils/css-color';
import {
  FaCloudUploadAlt as IconUpload,
  FaRegFileImage as IconNoImage,
} from 'react-icons/fa';
import { TbTrash as IconTrash } from 'react-icons/tb';
import type { BaseEditorImage } from '@odo/types/portal';
import {
  getImageSrc,
  isImageElement,
} from '@odo/screens/deal/editor/images-and-videos/helpers';
import Tooltip from '@odo/components/widgets/tooltip';
import styled from '@odo/lib/styled';
import { memo } from 'react';

export const IndicatorWrapper = styled(Flex)`
  box-shadow: 1px 2px 4px -2px hsl(240deg 33.33% 80% / 50%),
    1px 2px 8px -2px hsl(240deg 33.33% 80% / 25%);
`;

IndicatorWrapper.defaultProps = {
  bg: cssColor('foreground'),
  borderRadius: '50%',
  p: 1,
};

export const NewImageIndicator = () => (
  <Tooltip showDelay={500} content={() => 'Image will be uploaded on save'}>
    <IndicatorWrapper>
      <IconUpload color={cssColor('palette-turquoise')} size={18} />
    </IndicatorWrapper>
  </Tooltip>
);

// increasing padding by 1px and reducing size by 2px because the icon is just a bit bigger than it's peers
export const DeletedImageIndicator = ({ size = 16 }: { size?: number }) => (
  <Tooltip showDelay={500} content={() => 'Image will be deleted on save'}>
    <IndicatorWrapper p="5px">
      <IconTrash color={cssColor('palette-pink')} size={size} />
    </IndicatorWrapper>
  </Tooltip>
);

const ImagePreview = memo(
  ({
    url,
    file,
    dimensions,
    retainImageSize,
    onLoad,
  }: {
    // NOTE: we require the specific fields instead of the entire object
    // so that our memoization can work at full efficacy (eg. changes to position don't cause re-renders here)
    url: BaseEditorImage['url'];
    file: BaseEditorImage['file'];
    dimensions: number;
    retainImageSize?: boolean;
    onLoad?: (image: HTMLImageElement) => void;
  }) => (
    <Card overflow="hidden" maxWidth="min-content" mainProps={{ px: 0, py: 0 }}>
      <Flex
        width={`${dimensions}px`}
        height={`${dimensions}px`}
        maxWidth="min(90vw, 90vh)"
        maxHeight="min(90vw, 90vh)"
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundImage:
            'linear-gradient(45deg, hsl(206deg 14% 10% / 10%) 25%, transparent 25%), linear-gradient(135deg, hsl(206deg 14% 10% / 10%) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, hsl(206deg 14% 10% / 10%) 75%), linear-gradient(135deg, transparent 75%, hsl(206deg 14% 10% / 10%) 75%)',
          backgroundPosition: '0 0, 10px 0, 10px -10px, 0px 10px',
          backgroundSize: '20px 20px',
        }}
      >
        {!url && !file ? (
          <IconNoImage size={40} />
        ) : (
          <img
            src={getImageSrc({
              url,
              file,
              ...(!retainImageSize && { maxWidth: dimensions + 125 }),
            })}
            // NOTE: using the label reduces the efficacy of our memoization and we don't need unique alt text here
            alt="Preview"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
            }}
            onLoad={e => {
              if (e.target instanceof Element && isImageElement(e.target)) {
                onLoad && onLoad(e.target);
                // image urls generated with createObjectURL need to be revoked and will have the `blob:` protocol
                if (e.target.src.startsWith('blob:')) {
                  URL.revokeObjectURL(e.target.src);
                }
              }
            }}
            draggable="false"
          />
        )}
      </Flex>
    </Card>
  )
);

export default ImagePreview;
