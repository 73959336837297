import { Flex } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import { NavItem } from '@odo/components/layouts/app-container';
import Tooltip from '@odo/components/widgets/tooltip';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import { cssColor } from '@odo/utils/css-color';
import { FaArrowCircleRight as IconXTD } from 'react-icons/fa';
import styled from '@odo/lib/styled';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '@odo/screens/deal/editor/constants';

const BorderedNavItem = styled(NavItem)``;

BorderedNavItem.defaultProps = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'transparent',
};

const Title = () => {
  const currentProduct = useCurrentProduct();

  // return an empty element to keep the peace
  if (!currentProduct?.id && !currentProduct?.name) return <span />;

  // we've hidden the title component on mobile to bypass a layout issue TODO: fix layout at some point
  return (
    <Flex flexDirection="row" gap={[2, 3]} display={['none', 'flex']}>
      <NavItem overflow="hidden" px={[2, 3]} py={0} style={{ cursor: 'unset' }}>
        <Text
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {!!currentProduct?.id && (
            <>
              ID: <b style={{ fontWeight: 700 }}>{currentProduct.id}</b>
            </>
          )}
          {!!currentProduct?.id && currentProduct?.name && (
            <>&nbsp;&#183;&nbsp;</>
          )}
          {!!currentProduct?.name && <>{currentProduct.name}</>}
        </Text>
      </NavItem>

      {currentProduct.isXTD && (
        <Tooltip
          showDelay={250}
          content={() => 'This is an Extra Time Deal'}
          color={cssColor('palette-magenta')}
        >
          <BorderedNavItem
            justifyContent="center"
            height="42px"
            borderColor={cssColor('palette-magenta')}
          >
            <Flex gap={[1, 2]} px={[1, 2]} alignItems="center">
              <Heading
                fontSize={[1, 2]}
                fontWeight={700}
                color={cssColor('palette-magenta')}
              >
                XTD
              </Heading>

              <IconXTD size={16} color={cssColor('palette-magenta')} />
            </Flex>
          </BorderedNavItem>
        </Tooltip>
      )}

      {currentProduct.isNew && (
        <BorderedNavItem
          justifyContent="center"
          height="42px"
          borderColor={cssColor('palette-blue')}
        >
          <Text
            px={[1, 2]}
            fontWeight={700}
            color={cssColor('palette-blue')}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {!currentProduct.draft?.duplicatedDealId ? (
              <>This is a new deal.</>
            ) : (
              <>
                This deal is a duplicate of{' '}
                <Link
                  to={`${BASE_PATH}/${currentProduct.draft.duplicatedDealId}/buyer-and-supplier`}
                  target="_blank"
                  style={{
                    all: 'unset',
                    fontWeight: 800,
                    textDecoration: 'underline',
                  }}
                >
                  #{currentProduct.draft.duplicatedDealId}
                </Link>
              </>
            )}
          </Text>
        </BorderedNavItem>
      )}
    </Flex>
  );
};

export default Title;
