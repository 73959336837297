/**
 * We're replacing the RedPanda CurrentDealContext.uploadDeal function with our own.
 * Most of the new saving logic is written in Typescript @see @odo/helpers/rps-deals.ts
 * But we also need some wrapping logic for UI and passing in the RP context/deal/etc.
 *
 * We were originally just going to put this in the view file where save is triggered,
 * but there are two places where save is triggered and we don't want to duplicate code.
 *
 * NOTE: this can all be removed after BP-534
 */

import { generatePath } from 'react-router-dom';
import { saveRpsDeal } from '@odo/helpers/rps-deals.ts';
import { Deal } from 'models/Deal.jsx';
import {
  success,
  invertedSuccessColors,
  error,
  dismiss,
} from '@odo/utils/toast.tsx';
import { WizardSteps } from '../../components/shared/ProgressTrack';

const LOADING_TITLE = 'Uploading.';
const DEFAULT_TITLE = 'OneDayOnly | Buyers Portal';

let intervalId;
let originalTitle;
let errorToastId;

export const saveChanges = async ({
  currentDeal,
  canSaveCustomOptions,
  validate,
  saveActions,
  modal,
  navigate,
  location,
}) => {
  let newProductId;
  const isNewDeal = !currentDeal.deal.id;

  currentDeal.setBusyUploading(true);

  // start the document title loading interval
  if (!originalTitle) originalTitle = document.title;
  document.title = LOADING_TITLE;

  intervalId = setInterval(() => {
    if (document.title === `${LOADING_TITLE}..`) {
      document.title = LOADING_TITLE;
    } else {
      document.title = `${document.title}.`;
    }
  }, 750);

  try {
    if (errorToastId) dismiss(errorToastId);

    // check deal validity
    if (currentDeal.deal.getValidity.status === '') {
      throw new Error('Deal not ready for upload');
    }
    // check custom options validity
    if (canSaveCustomOptions && !validate()) {
      throw new Error('Custom Options have errors');
    }

    // trigger our new deal save
    const { productId, stockId, latestQty } = await saveRpsDeal({
      id: currentDeal.deal.id,
      dealModel: currentDeal.deal,
      newDealModel: new Deal(), // we use an empty/new deal model for applying only the changed fields
    });

    success('Deal saved!', {
      ...invertedSuccessColors,
      position: 'top-center',
    });

    if (isNewDeal) {
      newProductId = productId;
    }

    // trigger and wait for custom options save if needed
    if (canSaveCustomOptions && productId && stockId) {
      await saveActions({ productId, stockId, latestQty });
    }

    // trigger context update and add to recent deal list
    currentDeal.update();
    currentDeal._addToRecentDealsList(currentDeal.deal);

    // show a completed doc title
    document.title = 'Complete!!!';
  } catch (e) {
    errorToastId = error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : typeof e === 'string'
        ? e
        : 'Error saving deal'
    );

    // show a failed doc title
    document.title = 'Failed!!!';
  } finally {
    // cleanup and reset
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = undefined;
    }
    setTimeout(() => {
      document.title = originalTitle;
      originalTitle = DEFAULT_TITLE;
    }, 4000);

    currentDeal.setBusyUploading(false);
    modal.close();

    // if this is a new deal we need to update the location/history for future reloads
    if (isNewDeal && newProductId) {
      let path = '/old/deals/editor/:dealId?/buyer-and-supplier';
      try {
        const last = location.pathname.split('/').pop();
        if (WizardSteps.some(step => step.to === last)) {
          path = `/old/deals/editor/:dealId?/${last}`;
        }
      } catch (e) {}
      navigate(generatePath(path, { dealId: newProductId }));
    }
  }
};
