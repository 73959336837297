import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import {
  applyPresetCombinations,
  productCombinations,
} from './create/productCombinations';
import {
  RPSInput,
  RPSButton,
  RPSCard,
  RPSButtonStrip,
  RPSListContainer,
} from '@rps/web-components/build/react-wrappers';
import {
  templateType,
  dealType,
  dealSubTypeA,
  dealSubTypeB,
} from './create/data';
import { DealProduct } from 'models/Product.jsx';
import { toDateInputDate } from 'utils/sanitizedDate';
import { DateInputTemp } from 'components/uiComponents/DateInputTemp.jsx';
import { iconNames } from '@rps/web-components/build/web-components';
import { Deal } from 'models/Deal.jsx';
import { DealPriceAndCustomOptions } from 'models/PriceAndCustomOptions.jsx';
import { generatePath } from 'react-router-dom';
import odoUuid from '@odo/utils/uuid';
import { isPositiveCurrencyOnChange } from '@odo/utils/validation';
import { handleODOInput } from 'utils/odo-migration/forms';
import { Input } from '@odo/components/elements/form-fields';
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import { Grid } from '@odo/components/elements/layout/grid';
import { queryValidateAuth } from '@odo/graphql/user.ts';
import { loading, dismiss } from '@odo/utils/toast.tsx';

const VALIDATING_AUTH_TOAST_ID = 'validation-auth';

const Create = () => {
  const navigate = useNavigate();
  const currentDeal = useCurrentDealSource();

  const [validatingAuth, setValidatingAuth] = useState(false);

  const selectedDealHasCustomOptions =
    currentDeal.deal.priceAndCustomOptions.hasCustomOptions;

  const handleSelectHasCustomOptions = ev => {
    currentDeal.deal.priceAndCustomOptions.hasCustomOptions =
      ev.detail.buttonId;
    currentDeal.update(currentDeal.deal);
  };

  const productType = currentDeal.deal.productType;
  const handleSelectProductType = key => ev => {
    if (key === 'type') {
      productType.subtypeA = '';
      productType.subtypeB = '';
    } else if (key === 'subtypeA') {
      productType.subtypeB = '';
    }
    productType[key] = ev.detail.buttonId;

    productType.combination = [
      productType.type,
      productType.subtypeA,
      productType.subtypeB,
    ]
      .filter(x => !!x)
      .join('+');

    currentDeal.deal.productType = { ...productType };

    // Apply auto-populated values if applicable
    if (productCombinations[productType.combination]) {
      applyPresetCombinations(productType.combination, currentDeal.deal);
    } else {
      // Reset fields that might have changed
      applyPresetCombinations('reset', currentDeal.deal);
    }
    currentDeal.update(currentDeal.deal);
  };

  const handleSubmit = () => {
    setValidatingAuth(true);
    loading('Validating session before we continue...', {
      id: VALIDATING_AUTH_TOAST_ID,
      position: 'top-center',
    });

    queryValidateAuth({})
      .then(valid => {
        setValidatingAuth(false);
        dismiss(VALIDATING_AUTH_TOAST_ID);

        // NOTE: our urql client will automatically log the user out if the token is invalid, but just in case, return.
        if (!valid) return;

        // continue as usual
        if (!currentDeal.deal.meta.tmpId) {
          currentDeal.deal.meta.tmpId = odoUuid();
        }

        if (productCombinations[productType.combination]) {
          applyPresetCombinations(productType.combination, currentDeal.deal);
        } else {
          // Reset fields that might have changed
          applyPresetCombinations('reset', currentDeal.deal);
        }

        navigate(
          generatePath('/old/deals/editor/:dealId?/buyer-and-supplier', {
            dealId: currentDeal.deal.id || undefined,
          })
        );
      })
      .catch(error => {
        console.error(error);
        setValidatingAuth(false);
        dismiss(VALIDATING_AUTH_TOAST_ID);
      });
  };

  const handleCancel = () => navigate('/');

  const handleInput = field => ev => {
    currentDeal.deal.product.set(field, ev.target.value);
    currentDeal.update(currentDeal.deal);
  };

  const activeFromDate = toDateInputDate(
    new Date(currentDeal.deal.product.activeFromDate)
  );
  const activeToDate = toDateInputDate(
    new Date(currentDeal.deal.product.activeToDate)
  );

  const handleFromDateChange = ev => {
    handleInput(DealProduct.FIELDS.ACTIVE_FROM_DATE)(ev);
    const fromDate = new Date(currentDeal.deal.product.activeFromDate);
    const toDate = new Date(currentDeal.deal.product.activeToDate);

    if (toDate < fromDate) {
      handleInput(DealProduct.FIELDS.ACTIVE_TO_DATE)(ev);
    }
  };

  return (
    <Grid gap={[2, 3]}>
      <Grid gap={[2, 3]} gridTemplateColumns={['1fr', null, null, '1fr 1fr']}>
        <RPSCard>
          <div slot="header">
            <h5>Creating New Deal</h5>
          </div>
          <div style={{ display: 'none' }}>
            <RPSButtonStrip
              required
              selected={selectedDealHasCustomOptions}
              text="Custom Options"
              name="dealCreateSimpleComplex"
              cbClick={handleSelectHasCustomOptions}
              buttons={templateType}
            />
          </div>

          {selectedDealHasCustomOptions && (
            <div style={{ marginTop: '2rem' }}>
              <RPSButtonStrip
                required
                selected={productType?.type}
                text="Product Type"
                cbClick={handleSelectProductType('type')}
                name="dealCreateDealType"
                buttons={dealType}
              />
            </div>
          )}

          {(productType.type === 'product' ||
            productType.type === 'voucher') && (
            <div style={{ marginTop: '2rem' }}>
              <RPSButtonStrip
                required
                selected={productType?.subtypeA}
                text="Product Sub-Type A"
                cbClick={handleSelectProductType('subtypeA')}
                name="dealCreateProdTypeL2"
                buttons={dealSubTypeA[productType?.type]}
              />
            </div>
          )}

          {productType.subtypeA === 'alcohol' && (
            <div style={{ marginTop: '2rem' }}>
              <RPSButtonStrip
                required
                selected={productType?.subtypeB}
                text="Product Sub-Type B"
                cbClick={handleSelectProductType('subtypeB')}
                name="dealCreateProdTypeL3"
                buttons={dealSubTypeB[productType?.subtypeA]}
              />
            </div>
          )}
        </RPSCard>

        <RPSCard>
          <div slot="header">
            <h5>Basic Information</h5>
          </div>
          <p>Note: Can be filled in later.</p>
          <RPSListContainer
            css="#list-container { width: 100% }"
            className="vertical"
          >
            <RPSInput
              selectOnFocus
              label="Brand"
              required
              cbInput={handleInput(DealProduct.FIELDS.BRAND)}
              value={currentDeal.deal.product.brand}
              defaultValue={currentDeal.deal.product.brand}
            />
            <RPSInput
              selectOnFocus
              required
              cbInput={handleInput(DealProduct.FIELDS.NAME)}
              value={currentDeal.deal.product.name}
              label="Product Name"
            />
            <RPSInput
              selectOnFocus
              required
              cbInput={handleInput(DealProduct.FIELDS.SKU)}
              value={currentDeal.deal.product.sku}
              label="SKU"
            />
            <DateInputTemp
              required
              cbInput={handleFromDateChange}
              value={activeFromDate}
              type="date"
              label="Active From"
            />
            <DateInputTemp
              required
              cbInput={handleInput(DealProduct.FIELDS.ACTIVE_TO_DATE)}
              value={activeToDate}
              type="date"
              label="Active To"
            />
          </RPSListContainer>
        </RPSCard>
      </Grid>

      <RPSCard>
        <div slot="header">
          <h5>Price &amp; Cost</h5>
          <RPSListContainer
            css="#list-container { width: 100%; }"
            className="horizontal"
          >
            <Input
              label="Cost"
              value={currentDeal.deal.priceAndCustomOptions.cost}
              onChange={e =>
                isPositiveCurrencyOnChange(e.target.value) &&
                handleODOInput({
                  currentDeal,
                  model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
                  field: DealPriceAndCustomOptions.FIELDS.COST,
                })(e)
              }
              withPrefix
              required
              selectOnFocus
              matchRPStyles
            />

            <Input
              label="Price"
              value={currentDeal.deal.priceAndCustomOptions.price}
              onChange={e =>
                isPositiveCurrencyOnChange(e.target.value) &&
                handleODOInput({
                  currentDeal,
                  model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
                  field: DealPriceAndCustomOptions.FIELDS.PRICE,
                })(e)
              }
              withPrefix
              required
              selectOnFocus
              matchRPStyles
            />
            <Input
              label="Retail"
              value={currentDeal.deal.priceAndCustomOptions.retail}
              onChange={e =>
                isPositiveCurrencyOnChange(e.target.value) &&
                handleODOInput({
                  currentDeal,
                  model: Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
                  field: DealPriceAndCustomOptions.FIELDS.RETAIL,
                })(e)
              }
              withPrefix
              selectOnFocus
              matchRPStyles
            />
          </RPSListContainer>
        </div>
      </RPSCard>

      <RPSCard>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <RPSButton cbClick={handleCancel} className="error">
            Back
            <rps-svg slot="slot-left" svg={iconNames.angleLeft} />
          </RPSButton>
          <RPSButton cbClick={handleSubmit} disabled={validatingAuth}>
            Continue
            <rps-svg slot="slot-right" svg={iconNames.angleRight} />
          </RPSButton>
        </div>
      </RPSCard>
    </Grid>
  );
};

const Wrapper = () => {
  const currentDeal = useCurrentDealSource();
  const { clearActions } = useCustomOptionsEditorContext();

  const [ready, setReady] = useState(false);

  /**
   * Create a new deal model each time we navigate here.
   *
   * NOTE: this is opposed to manually creating the new model before triggering the navigation.
   */
  useEffect(() => {
    if (ready) return;

    currentDeal.newDeal();
    clearActions();

    // schedule the ready state update (giving enough time for RP deal context to update)
    setTimeout(() => setReady(true), 150);
  }, [ready, currentDeal, clearActions]);

  if (!ready) return null;

  return <Create />;
};

export default Wrapper;
