import { cssColor } from '@odo/utils/css-color';
import { DeletedImageIndicator } from '@odo/screens/deal/editor/widgets/images/image-preview';
import styled from '@odo/lib/styled';

const Wrapper = styled.div`
  --color: ${cssColor('palette-pink')};
  --width: 3px;

  position: absolute;
  inset: 0;
  z-index: 2;
  border-radius: 0.75rem;
  border: var(--width) solid var(--color);
  background: hsla(0deg 0% 100% / 0.75);

  cursor: not-allowed;
  pointer-events: auto;
`;

/**
 * data:image source can be found in ./repeatable-pink-line.svg
 * for more instantaneous loading it was converted using this tool:
 * @see https://www.svgviewer.dev/svg-to-data-uri
 */
const BackgroundLines = styled.div`
  position: absolute;
  inset: 0;
  background: top repeat
    url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpolygon%20fill%3D%22hsl(337deg%2093%25%2064%25)%22%20points%3D%220%2C15%2015%2C0%2020%2C0%200%2C20%22%20%2F%3E%3Cpolygon%20fill%3D%22hsl(337deg%2093%25%2064%25)%22%20points%3D%2220%2C15%2020%2C20%2015%2C20%22%20%2F%3E%3C%2Fsvg%3E');
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 3;
  display: grid;
  place-content: center;
`;

const DeletedOverlay = () => (
  <Wrapper>
    <BackgroundLines />
    <IndicatorWrapper>
      <DeletedImageIndicator size={36} />
    </IndicatorWrapper>
  </Wrapper>
);

export default DeletedOverlay;
