import Card from '@odo/components/elements/card';
import { Radio } from '@odo/components/elements/form-fields';
import { Box, Grid } from '@odo/components/elements/layout';
import { type ReactNode } from 'react';

const RadioCard = ({
  isSelected,
  selectCard,
  children,
}: {
  isSelected: boolean;
  selectCard: () => void;
  children: ReactNode;
}) => (
  <Card
    isSelected={isSelected}
    onClick={selectCard}
    borderWidth="2px"
    style={{ cursor: 'pointer' }}
  >
    <Grid gap={[2, 3]} gridTemplateColumns="auto 1fr">
      <Radio checked={isSelected} readOnly />
      <Box>{children}</Box>
    </Grid>
  </Card>
);

export default RadioCard;
